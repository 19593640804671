import { toast } from "react-toastify";


const dealWithError = async (response) => {
  const error = await response.json();
  toast.error(error.message);
};

const ErrorUtils = {
  dealWithError,
};

export default ErrorUtils;
