import { createContext, useState } from "react";
import SettingsService from "../../services/settings.service";

export const SettingsContext = createContext();

const SettingsContextProvider = ({ children }) => {
  const { getUserSettings } = SettingsService();
  const [defaultSearchEngine, setDefaultSearchEngine] = useState("Google");
  const [defaultNumberOfAnswers, setDefaultNumberOfAnswers] = useState(3);

  const checkSettings = async () => {
    const response = await getUserSettings();
    if (response) {
      setDefaultSearchEngine(response?.response?.defaultSearchEngine);
      setDefaultNumberOfAnswers(response?.response?.defaultNumberOfAnswers);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        checkSettings,
        defaultSearchEngine,
        defaultNumberOfAnswers,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
