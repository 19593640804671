const PathConstants = {
  HOME: "/",
  HISTORY: "/history",
  FAQS: "/faqs",
  ABOUT: "/about",
  PRIVACYPOLICY: "/privacypolicy",
  ADMIN: "/admin",
};

export default PathConstants;
