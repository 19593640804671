import React from "react";
import PathConstants from "./pathConstants";

const Home = React.lazy(() => import("../pages/home"));
const History = React.lazy(() => import("../pages/history"));
const Admin = React.lazy(() => import("../pages/admin"));
const Faqs = React.lazy(() => import("../pages/faqs"));
const About = React.lazy(() => import("../pages/about"));
const PrivacyPolicy = React.lazy(() => import("../pages/privacyPolicy"));

const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.HISTORY, element: <History /> },
  { path: PathConstants.ADMIN, element: <Admin /> },
  { path: PathConstants.FAQS, element: <Faqs />},
  { path: PathConstants.ABOUT, element: <About /> },
  { path: PathConstants.PRIVACYPOLICY, element: <PrivacyPolicy /> }
];

export default routes;
