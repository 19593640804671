import "./style.css";
import Divider from "../divider";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
            <div className="footer">
                <div className="footer-links">
                    <Link style={{width:"150px"}} to="/about">About Us</Link>
                    <Link style={{width:"150px"}} to="/privacypolicy">Privacy Policy</Link>
                    <Link style={{width:"150px"}} to="/faqs">FAQs</Link>
                </div>
                <Divider />
                <div className="footer__container">
                    <p>© Debiaser 2023</p>
                </div>
            </div>
    );
    };

export default Footer;