// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik';
    margin: auto;
}

body {
    background: linear-gradient(to bottom, #F5F5F5, #DEDEFF);
    background-size: cover;
    background-attachment: fixed;
    margin: auto;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,oBAAoB;IACpB,YAAY;AAChB;;AAEA;IACI,wDAAwD;IACxD,sBAAsB;IACtB,4BAA4B;IAC5B,YAAY;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');\n\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: 'Rubik';\n    margin: auto;\n}\n\nbody {\n    background: linear-gradient(to bottom, #F5F5F5, #DEDEFF);\n    background-size: cover;\n    background-attachment: fixed;\n    margin: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
