import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppBar from "../components/AppBar.jsx";
import Footer from "../components/footer/index.js";

const Layout = () => {
  return (
    <div style={{display:"flex", flexDirection:"column", height:"100vh"}}>
      <AppBar />
      <main>
        <Suspense fallback={<div>Loading ...</div>}>
          <Outlet />
        </Suspense>
      </main>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
      <Footer />
    </div>
  );
};

export default Layout;
