import { toast } from "react-toastify";
import ErrorUtils from "../utils/error.utils";

const URL = process.env.REACT_APP_API_URL;

const getUserSettings = async (token) => {
  try {
    const response = await fetch(`${URL}v1/settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      ErrorUtils.dealWithError(response);
      return;
    }
    return response.json();
  } catch (error) {
    toast.error(error.message);
  }
};

const createOrUpdateUserSettings = async (
  token,
  defaultSearchEngine,
  defaultNumberOfAnswers
) => {
  try {
    const response = await fetch(`${URL}v1/settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        defaultSearchEngine: defaultSearchEngine,
        defaultNumberOfAnswers: defaultNumberOfAnswers,
      }),
    });
    if (response.status !== 200) {
      ErrorUtils.dealWithError(response);
      return;
    }
    toast.success("Settings saved successfully");
    return response.json();
  } catch (error) {
    toast.error(error.message);
  }
};

const SettingsApi = {
  getUserSettings,
  createOrUpdateUserSettings,
};

export default SettingsApi;
