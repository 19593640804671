import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import routes from "./routes";
import { Helmet } from "react-helmet";
function App() {
  return (
    <>
      <Helmet>
        <title>My App</title>
        <meta name="description" content="This is my React app" />
        {/* Add more meta tags or headers as needed */}
      </Helmet>
      <RouterProvider
        router={createBrowserRouter([
          { element: <Layout />, children: routes },
        ])}
      />
    </>
  );
}

export default App;
