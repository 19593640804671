import { useAuth0 } from "@auth0/auth0-react";
import SettingsApi from "../api/settings.api";

const SettingsService = () => {
  const { getAccessTokenSilently } = useAuth0();

  const createOrUpdateUserSettings = async (searchEngine, numberOfResponses) => {
    const token = await getAccessTokenSilently();
    const response = await SettingsApi.createOrUpdateUserSettings(
      token,
      searchEngine,
      numberOfResponses
    );
    return response;
  };

  const getUserSettings = async () => {
    const token = await getAccessTokenSilently();
    const response = await SettingsApi.getUserSettings(token);
    return response;
  };

  return {
    createOrUpdateUserSettings,
    getUserSettings,
  };
};

export default SettingsService;