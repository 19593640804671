// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
    position: relative;
    width: 80%;
    height: 5px; /* adjust as needed */
    z-index: 1;
    overflow: hidden;
}

.divider:before {
    content: "";
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background: linear-gradient(to right, #E5E5FC, #3F51B5, #E5E5FC);
    z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/components/divider/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW,EAAE,qBAAqB;IAClC,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,UAAU;IACV,WAAW;IACX,gEAAgE;IAChE,WAAW;AACf","sourcesContent":[".divider {\n    position: relative;\n    width: 80%;\n    height: 5px; /* adjust as needed */\n    z-index: 1;\n    overflow: hidden;\n}\n\n.divider:before {\n    content: \"\";\n    position: absolute;\n    top: -5px;\n    bottom: -5px;\n    left: -5px;\n    right: -5px;\n    background: linear-gradient(to right, #E5E5FC, #3F51B5, #E5E5FC);\n    z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
